// ** Reducers Imports

// common imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import ecommerce from '@src/views/pages/catalog/store'

const commonReducers = {
  auth,
  navbar,
  layout,
  ecommerce
}

const rootReducer = {
  ...commonReducers
}

export default rootReducer
