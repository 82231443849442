// ** Checks if an object is empty (returns boolean)

// import {useDispatch} from "react-redux"
import {store} from "../redux/store"
import {handleLogout} from "../redux/authentication"
import ToastUtil from "../views/extensions/toastify/ToastrUtil"

export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const logout = () => {
  store.dispatch(handleLogout())
  window.location = '/login'
}

export const getAuthHeaders = (key) => {
  return new Headers({
    "x-api-key": key
    // "Content-Type": "application/x-www-form-urlencoded"
  })
}

export const apiQuery = async (url, method, api_key, data = "") => {
  try {
    const timeout = 2 * 60 * 1000

    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)

    const init = {
      method,
      headers: getAuthHeaders(api_key),
      timeout,
      signal: controller.signal
    }

    if (data !== "") {
      init['body'] = data
    }

    const response = await fetch(url, init)

    clearTimeout(id)

    const jsonResponse = await response.json()
    if (!jsonResponse.status) {
      if (jsonResponse.error && jsonResponse.error.message === "Expired token") {
        logout()
      }
    }

    return jsonResponse

  } catch (error) {
    // Timeouts if the request takes longer than 2 minutes
    if (error.name === 'AbortError') {
      ToastUtil.trigger("error", "Error", 'Request took too long. Please try again later')
      return {status: false, message: 'Request took too long. Please try again later'}
    }
  }
}

/*export const apiQuery = async (url, method, token, data = "") => {
  const init = {
    method,
    headers: getAuthHeaders(token)
  }
  if (data !== "") {
    init['body'] = JSON.stringify(data)
  }

  const response = await fetch(url, init)
  const jsonResponse = await response.json()
  if (!jsonResponse.status) {
    if (jsonResponse.error && jsonResponse.error.message === "Expired token") {
      logout()
    }
  }

  return jsonResponse
}*/

export const getHomeRouteForLoggedInUser = userRole => {
  console.log(userRole)
  return '/pages/two-steps-basic'
  // if (userRole === 'agent') return '/pages/two-steps-basic'
  // if (userRole === 'client') return '/access-control'
  // return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#c5200f', // for option hover bg-color
    primary: '#c5200f', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})
