// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {store} from '@store/store'

// ** Axios Imports
import axios from 'axios'
import themeConfig from "../../../../configs/themeConfig"
import {apiQuery} from "../../../../utility/Utils"

export const getProducts = createAsyncThunk('appEcommerce/getProducts', async () => {
    const url = `${themeConfig.app.serverUrl}/catalog`
    const response = await apiQuery(url, "GET", themeConfig.app.api_key)

    const popular = []
    const topup = []
    const voucher = []
    response.data.map(item => {
        if (item.brand_popular === "1") {
            popular.push(item)
        }
        if (item.brand_type === "voucher") {
            voucher.push(item)
        }
        if (item.brand_type === "topup") {
            topup.push(item)
        }
    })

    return {products: response.data, popular, topup, voucher, total: response.data.length}
})

export const sortCompare = key => (a, b) => {
    const fieldA = a[key]
    const fieldB = b[key]

    let comparison = 0
    if (fieldA > fieldB) {
        comparison = 1
    } else if (fieldA < fieldB) {
        comparison = -1
    }
    return comparison
}

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const filterProducts = createAsyncThunk('appEcommerce/filterProducts', async filterData => {
    const {params, type} = filterData
    const { q, sortBy, perPage, page } = params
    const data = store.getState()
    const products = data.ecommerce

    let filteredData = []

    if (type === 'popular') {
        filteredData = products.popular
    } else if (type === 'voucher') {
        filteredData = products.voucher
    } else {
        filteredData = products.topup
    }

    const queryLowered = q.toLowerCase()
    filteredData = filteredData.filter(product => product.brand_name.toLowerCase().includes(queryLowered))

    let sortDesc = false
    const sortByKey = (() => {
        if (sortBy === 'name-desc') {
            sortDesc = true
            return 'brand_name'
        }
        if (sortBy === 'name-asc') {
            return 'brand_name'
        }

        return 'brand_name'
    })()

    const sortedData = filteredData.sort(sortCompare(sortByKey))
    if (sortDesc) sortedData.reverse()

    const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

    return {params, products: paginatedData, total: filteredData.length, type}
})

export const createPurchase = async (args, denomination) => {
    const formData = new FormData()
    formData.append('ean', denomination.denomination_ean)
    formData.append('currency', denomination.denomination_currency)
    formData.append('price', denomination.denomination_price)
    formData.append('voucher', args.points)
    formData.append('email', args.email)
    if (args.account !== '') {
        formData.append('account_id', args.account)
    }
    if (args.zone !== '') {
        formData.append('zone_id', args.zone)
    }
    if (args.server !== '') {
        formData.append('server_id', args.server.serverId)
    }
    const url = `${themeConfig.app.serverUrl}/order`
    return await apiQuery(url, "POST", themeConfig.app.api_key, formData)
}

export const confirmPurchase = async (key, role = null) => {
    const formData = new FormData()
    formData.append('key', key)

    if (role) {
        formData.append('packed_role_id', role)
    }

    const url = `${themeConfig.app.serverUrl}/order`
    return await apiQuery(url, "POST", themeConfig.app.api_key, formData)
}

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (id, { dispatch, getState }) => {
    const response = await axios.post('/apps/default/cart', { productId: id })
    await dispatch(getProducts(getState().ecommerce.params))
    return response.data
})

export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async () => {
    const response = await axios.get('/apps/default/cart')
    return response.data
})

export const getProduct = createAsyncThunk('appEcommerce/getProduct', async slug => {
    const response = await axios.get(`/apps/ecommerce/products/${slug}`)
    return response.data
})

export const getInvoice = createAsyncThunk('datatables/getInvoice', async (data) => {
    const {order_id, userData} = data
    const url = new URL(`${themeConfig.app.serverUrl}/dealer/purchase_voucher_detail?order_id=${order_id}`)
    const response = await apiQuery(url, "GET", userData.token)
    return response
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (id, { dispatch }) => {
    await axios.delete(`/apps/default/cart/${id}`)
    dispatch(getCartItems())
    return id
})

export const appEcommerceSlice = createSlice({
    name: 'appEcommerce',
    initialState: {
        cart: [],
        popularParams: {},
        popular: [],
        totalPopular: 0,

        voucherParams: {},
        voucher: [],
        totalVoucher: 0,

        topupParams: {},
        topup: [],
        totalTopup: 0,

        products: [],
        productDetail: {},
        invoice: {}
    },
    reducers: {
        handlePurchaseInvoice: (state, action) => {
            state.invoice = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                state.products = action.payload.products
                state.totalProducts = action.payload.total
                state.popular = action.payload.popular
                state.voucher = action.payload.voucher
                state.topup = action.payload.topup
            })
            .addCase(filterProducts.fulfilled, (state, action) => {
                if (action.payload.type === 'popular') {
                    state.popularParams = action.payload.products
                    state.popular = action.payload.params
                    state.totalPopular = action.payload.total
                } else if (action.payload.type === 'voucher') {
                    state.voucher = action.payload.products
                    state.voucherParams = action.payload.params
                    state.totalVoucher = action.payload.total
                } else if (action.payload.type === 'topup') {
                    state.topup = action.payload.products
                    state.topupParams = action.payload.params
                    state.totalTopup = action.payload.total
                }
            })
            .addCase(getCartItems.fulfilled, (state, action) => {
                state.cart = action.payload.products
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.productDetail = action.payload.product
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.invoice = action.payload
            })
    }
})

export const {handlePurchaseInvoice} = appEcommerceSlice.actions
export default appEcommerceSlice.reducer
