import { Fragment } from 'react'
import Avatar from '@components/avatar'
import { Check } from 'react-feather'
import { toast, Bounce } from 'react-toastify'

const NotificationService = {
    trigger: (type, head, body) => {
        return toast.info(<Toastr type={type} head={head} body={body} />, { icon: false, transition: Bounce, hideProgressBar: true })
    }
}

const Toastr = (props) => {
    const type = props.type === "error" ? "danger" : props.type
    return (
        <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <Avatar size='sm' color={type} icon={<Check size={12} />} />
                    <h6 className={`text-${type} ms-50 mb-0`}>{props.head}</h6>
                </div>
            </div>
            <div className='toastify-body'>
                <span>{props.body}</span>
            </div>
        </Fragment>
    )
}

export default NotificationService
