// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import themeConfig from "../configs/themeConfig"
import {apiQuery} from "../utility/Utils"

export const getBookmarks = createAsyncThunk('layout/getBookmarks', async () => {
  const response = await axios.get('/api/bookmarks/data')
  return {
    bookmarks: response.data.bookmarks
  }
})

export const getWallets = createAsyncThunk('layout/getWallets', async (userData) => {
  let url
  if (userData.profile === 'dealer') {
    url = `${themeConfig.app.serverUrl}/dealer/vendor_balance`
  } else {
    url = `${themeConfig.app.serverUrl}/subdealer/current_balance`
  }
  const response = await apiQuery(url, "GET", userData.token)
  return response.data
})

export const updateBookmarked = createAsyncThunk('layout/updateBookmarked', async id => {
  await axios.post('/api/bookmarks/update', { id })
  return id
})

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    query: '',
    bookmarks: [],
    suggestions: [],
    wallets: []
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    },
    handleSuggestions: (state, action) => {
      state.suggestions = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(getWallets.fulfilled, (state, action) => {
        state.wallets = action.payload
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find(item => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handleSearchQuery, handleSuggestions } = layoutSlice.actions

export default layoutSlice.reducer
