import themeConfig from "../../../configs/themeConfig"

// ** Auth Endpoints
export default {
  // loginEndpoint: 'https://testapi-hub.mintroute.com/api/v1/agent/authenticate',
  // otpEndptpoint: 'https://testapi-hub.mintroute.com/api/v1/agent/otp',

  loginEndpoint: `${themeConfig.app.serverUrl}/auth/authenticate`,
  otpEndptpoint: `${themeConfig.app.serverUrl}/auth/otp`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // For Testing purpose
  loginEndPointDta: 'https://61c5715bc003e70017b796ff.mockapi.io/users',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
